<template>
  <v-card :loading="loading">
    <div class="d-flex">
      <div>
        <v-card-title class="text-base font-weight-semibold"> المصاريف الكلية </v-card-title>
        <v-card-text class="d-flex align-center">
          <h3 class="font-weight-semibold">
            {{
              numberWithComma(
                (expenses.statistic.allMoney ? expenses.statistic.allMoney : 0) +
                  (salary.allPayedAmount ? salary.allPayedAmount : 0),
              ) + (salary.currencySymbol ? salary.currencySymbol : '')
            }}
          </h3>
          <!-- <span class="text-xs ms-1" :class="checkChange(change) ? 'success--text' : 'error--text'"> {{ change }}</span> -->
        </v-card-text>
        <v-card-text>
          <!-- <v-chip small color="secondary" class="v-chip-light-bg font-weight-semibold secondary--text">
            Last Week
          </v-chip> -->
        </v-card-text>
      </div>
      <v-spacer></v-spacer>
      <div class="illustrator-img">
        <v-img width="86" src="@/assets/images/avatars/10.png" v-if="!loading"></v-img>
      </div>
    </div>
  </v-card>
</template>

<script>
import numberWithComma from '@/constant/number'

export default {
  props: {
    expenses: {
      type: Object,
      default: {
        statistic: {
          currencySymbol: ' IQD',
          allMoney: 0,
          allMoneyByDay: 0,
          allMoneyByMonth: 0,
        },
        lastFive: [],
      },
    },

    loading: {
      type: Boolean,
      default: false,
    },

    salary: {
      type: Object,
      default: {
        allAmount: 0,
        allLecturesNumber: 0,
        allPerLecturesPrice: 0,
        allWatchNumber: 0,
        allPerWatchPrice: 0,
        allAdditional: 0,
        allDiscounts: 0,
        allLectures: 0,
        allWatch: 0,
        allPayedAmount: 0,
        currencySymbol: ' IQD',
      },
    },

    reports: {
      type: Object,
      default: () => ({
        studentData: {
          salaryAmountStudent: '0',
          paymentAmountStudent: '0',
          discountAmountStudent: '0',
          currencySymbol: ' IQD',
        },
        otherData: {
          paymentAmount: '0',
          salaryAmount: '0',
          discountAmount: '0',
        },
        allData: {
          paymentAmount: '0',
          salaryAmount: '0',
          discountAmount: '0',
          salaryDiscountAmount: '0',
        },
      }),
    },
  },
  setup () {
    const checkChange = value => {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    }

    return { checkChange, numberWithComma }
  },
}
</script>

<style lang="scss" scoped>
.illustrator-img {
  position: absolute;
  right: 5%;
  bottom: 0;
}

.v-application {
  &.v-application--is-rtl {
    .illustrator-img {
      left: 5%;
      right: unset;
    }
  }
}
</style>

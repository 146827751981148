<template>
  <div>
    <v-row class="mb-4 d-flex justify-center">
      <v-col md="4" cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tableModifier.start_date"
                      dense
                      label="من"
                      outlined
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="tableModifier.start_date" @input="menuStartDate = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tableModifier.end_date"
                      dense
                      label="الى"
                      outlined
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="tableModifier.end_date" @input="menuEndDate = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn tile color="success" @click="handleDownload" :loading="xlsxData.downloadLoading">
                  تحميل اكسل
                  <v-icon right> fa-download </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-10 avatar">
      <v-col cols="12" md="3" sm="6" order="1" class="align-self-end mt-xs-10">
        <statistics-card-with-images-girl :reports="reports" :salary="salary" :expenses="expenses" :loading="loading">
        </statistics-card-with-images-girl>
      </v-col>

      <v-col cols="12" md="3" sm="6" order="2" class="align-self-end">
        <statistics-card-with-images-boy :reports="reports" :salary="salary" :expenses="expenses" :loading="loading">
        </statistics-card-with-images-boy>
      </v-col>

      <v-col cols="12" md="6" sm="12" order="5" order-md="3">
        <crm-statistics-card :salary="salary" :loading="loading"></crm-statistics-card>
      </v-col>

      <!-- <v-col cols="12" md="3" sm="6" order="3" order-md="4">
        <crm-total-sales></crm-total-sales>
      </v-col>

      <v-col cols="12" md="3" sm="6" order="4" order-md="5">
        <crm-revenue-report></crm-revenue-report>
      </v-col> -->

      <v-col cols="12" md="12" sm="12" order-md="4">
        <crm-sales-overview :reports="reports" :loading="loading"></crm-sales-overview>
      </v-col>

      <v-col cols="12" md="4" order-md="5">
        <ecommerce-card-website-statistics :expenses="expenses" :loading="loading"></ecommerce-card-website-statistics>
      </v-col>
      <v-col cols="12" md="8" order-md="7">
        <v-card :loading="loading">
    <v-card-title class="align-start">
      <span>المصاريف الشهرية</span>
      <v-spacer></v-spacer>
    </v-card-title>
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template slot="item.allMoney" slot-scope="props">
              <v-chip color="primary" dark>{{ numberWithComma(props.item.allMoney) }}</v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
import StatisticsCardAreaChart from '@core/components/statistics-card/StatisticsCardAreaChart.vue'
import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
import EcommerceCardWebsiteStatistics from './EcommerceCardWebsiteStatistics.vue'
import StatisticsCardWithImagesBoy from './StatisticsCardWithImagesBoy.vue'
import StatisticsCardWithImagesGirl from './StatisticsCardWithImagesGirl.vue'
// icons

// demos
import numberWithComma from '@/constant/number'
import reportsApi from '@/api/reports'
import CrmSalesOverview from './CrmSalesOverview.vue'
import CrmStatisticsCard from './CrmStatisticsCard.vue'

export default {
  components: {
    StatisticsCardWithImages,
    StatisticsCardAreaChart,
    StatisticsCardVertical,

    EcommerceCardWebsiteStatistics,
    CrmStatisticsCard,
    CrmSalesOverview,
    StatisticsCardWithImagesGirl,
    StatisticsCardWithImagesBoy,
  },

  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,

      tableModifier: {
        start_date: null,
        end_date: null,
      },
      tableOptions: {},
      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          { text: 'المبلغ الكلي', sortable: false, value: 'allMoney' },
          { text: 'السنة', sortable: false, value: '_id.year' },
          { text: 'الشهر', sortable: false, value: '_id.month' },
        ],
      },

      date: null,

      dateString: null,

      loading: false,

      expenses: {
        statistic: {
          currencySymbol: ' IQD',
          allMoney: 0,
          allMoneyByDay: 0,
          allMoneyByMonth: 0,
        },
        lastFive: [],
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'التقارير',
        autoWidth: true,
        bookType: 'xlsx',
      },

      reports: {
        payment_amount: 0,
        price: 0,
        discount_amount: 0,
      },

      salary: {
        allAmount: '0',
        allLecturesNumber: '0',
        allPerLecturesPrice: '0',
        allWatchNumber: '0',
        allPerWatchPrice: '0',
        allAdditional: '0',
        allDiscounts: '0',
        allLectures: '0',
        allWatch: '0',
        allPayedAmount: '0',
        currencySymbol: ' IQD',
      },

      ratingsOptions: {
        statTitle: 'صافي الدخل',
        statistics: 0,
        change: '+38%',
        chipText: 'Year of 2021',
        chipColor: 'primary',
        avatar: require('@/assets/images/avatars/9.png'),
        avatarWidth: '111',
      },

      sessionsOptions: {
        statTitle: 'المصاريف الكلية',
        statistics: 0,
        change: '-22%',
        chipText: 'Last Week',
        chipColor: 'secondary',
        avatar: require('@/assets/images/avatars/10.png'),
        avatarWidth: '86',
      },
    }
  },

  created() {
    this.getData()
    this.getDataAxios()
  },

  watch: {
    'tableModifier.start_date': {
      handler() {
        this.getData()
      },
      // deep: true,
    },
    tableOptions: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },

    'tableModifier.end_date': {
      handler() {
        this.getData()
      },
      // deep: true,
    },
  },

  methods: {
    async getDataAxios() {
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await reportsApi.getExpensesGroupedByMonth({
        page,
        limit: itemsPerPage,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
      }
    },

    async getData() {
      this.loading = true

      const date = new Date()
      const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]

      const res = await reportsApi.getReportsExpenses(this.tableModifier.start_date, this.tableModifier.end_date)

      const resReports = await reportsApi.getReports(this.tableModifier.start_date, this.tableModifier.end_date)

      const resSalary = await reportsApi.getReportsSalary(
        '2022-2023',
        this.tableModifier.start_date,
        this.tableModifier.end_date,
      )

      this.expenses = res.data.results

      this.reports = resReports.data.results

      this.salary = resSalary.data.results
      this.loading = false
    },

    handleDownload() {
      this.xlsxData.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['الاسم', 'المبلغ']

        const filterVal = ['name', 'amount']

        // const { list } = this
        const data = this.formatJson(filterVal, [
          {
            name: ['صافي الدخل'],
            amount: [this.getAllIncomes()],
          },
          {
            name: ['المصاريف الكلية'],
            amount: [this.getAllPayment()],
          },

          {
            name: ['الرواتب المدفوعة'],
            amount: [this.getPaidSalary()],
          },

          // {
          //   name: ['المحاظرات والمراقبات'],
          //   amount: [this.getSalaryRewards()],
          // },

          {
            name: ['خصومات الرواتب'],
            amount: [this.getSalaryDiscounts()],
          },

          {
            name: ['الفواتير المبالغ الكلية'],
            amount: [this.getOtherSalaryAllAmount()],
          },

          {
            name: ['الفواتير المبالغ المستحصلة'],
            amount: [this.getOtherSalaryReceived()],
          },

          {
            name: ['الفواتير الخصومات الكلية'],
            amount: [this.getOtherSalaryDiscounts()],
          },

          {
            name: ['الفواتير الديون المتبقية'],
            amount: [this.getOtherSalaryDept()],
          },

          {
            name: ['المصاريف'],
            amount: [this.getAllExpenses()],
          },
        ])
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    getAllIncomes() {
      return (
        (this.reports.payment_amount ? this.reports.payment_amount : 0) -
        ((this.expenses.statistic.allMoney ? this.expenses.statistic.allMoney : 0) +
          (this.salary.allPayedAmount ? this.salary.allPayedAmount : 0))
      )
    },

    getAllPayment() {
      return (
        (this.expenses.statistic.allMoney ? this.expenses.statistic.allMoney : 0) +
        ((this.salary.allPayedAmount ? this.salary.allPayedAmount : 0) -
          (this.salary.allDiscounts ? this.salary.allDiscounts : 0))
      )
    },

    getPaidSalary() {
      return this.salary.allPayedAmount ? this.salary.allPayedAmount : 0
    },

    getSalaryRewards() {
      return (this.salary.allLectures ? this.salary.allLectures : 0) + (this.salary.allWatch ? this.salary.allWatch : 0)
    },

    getSalaryDiscounts() {
      return this.salary.allDiscounts ? this.salary.allDiscounts : 0
    },

    getOtherSalaryAllAmount() {
      return this.reports.total_amount ? this.reports.total_amount : 0
    },

    getOtherSalaryReceived() {
      return this.reports.payment_amount ? this.reports.payment_amount : 0
    },

    getOtherSalaryDiscounts() {
      return this.reports.discount_amount ? this.reports.discount_amount : 0
    },

    getOtherSalaryDept() {
      return this.reports.total_amount - this.reports.payment_amount - this.reports.discount_amount
    },

    getInvoiceIncomesAllAmount() {
      return this.reports.total_amount ? this.reports.total_amount : 0
    },

    getInvoiceIncomesReceived() {
      return this.reports.paymentAmount ? this.reports.paymentAmount : 0
    },

    getInvoiceIncomesDiscounts() {
      return this.reports.discount_amount ? this.reports.discount_amount : 0
    },

    getInvoiceIncomesDept() {
      return this.reports.price - this.reports.payment_amount - this.reports.discount_amount
    },

    getAllExpenses() {
      return this.expenses.statistic.allMoney ? this.expenses.statistic.allMoney : 0
    },
    numberWithComma,
  },
}
</script>

<template>
  <v-card :loading="loading">
    <v-card-title class="align-start">
      <span class="font-weight-semibold">الرواتب</span>

      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3">
        <v-icon class="cursor-pointer">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <!-- <v-card-subtitle class="mb-7">
      <span class="font-weight-semibold text--primary me-1">Total 48.5% growth</span>
      <span>😎 this month</span>
    </v-card-subtitle> -->

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" class="d-flex align-center">
          <v-avatar size="44" color="primary" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiTrendingUp }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">المدفوعة</p>
            <h3 class="font-weight-bold">
              {{
                numberWithComma(salary.allPayedAmount ? salary.allPayedAmount : 0) +
                (salary.currencySymbol ? salary.currencySymbol : '')
              }}
            </h3>
          </div>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex align-center">
          <v-avatar size="44" color="warning" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ icons.mdiLabelOutline }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">الخصومات</p>
            <h3 class="font-weight-bold">
              {{
                numberWithComma(salary.allDiscounts ? salary.allDiscounts : 0) +
                (salary.currencySymbol ? salary.currencySymbol : '')
              }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import numberWithComma from '@/constant/number'
import { mdiAccountOutline, mdiDotsVertical, mdiLabelOutline, mdiTrendingUp } from '@mdi/js'
export default {
  props: {
    salary: {
      type: Object,
      default: {
        allAmount: 0,
        allLecturesNumber: 0,
        allPerLecturesPrice: 0,
        allWatchNumber: 0,
        allPerWatchPrice: 0,
        allAdditional: 0,
        allDiscounts: 0,
        allLectures: 0,
        allWatch: 0,
        allPayedAmount: 0,
        currencySymbol: ' IQD',
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const allAmount = props.salary.allAmount
    const statisticsData = [
      {
        title: 'المدفوعات',
        total: allAmount,
      },
      {
        title: 'المحاظرات والمراقبات',
        total: '12.5k',
      },
      {
        title: 'الخصومات',
        total: '1.54k',
      },
    ]
    const resolveStatisticsIconVariation = data => {
      if (data === 'المدفوعات') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'المحاظرات والمراقبات') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'الخصومات') return { icon: mdiLabelOutline, color: 'warning' }
      return { icon: mdiAccountOutline, color: 'success' }
    }
    return {
      statisticsData,
      resolveStatisticsIconVariation,
      numberWithComma,
      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
      },
    }
  },
}
</script>

import axios from 'axios'

class reportsApi {
  async getReports(start_date, end_date) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    const response = await axios.post('counting/analysis/invoice', {
      start_date,
      end_date,
    })
    return response
  }
  async getExpensesGroupedByMonth({ page, limit }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(`/counting/analysis/getExpensesGroupedByMonth?page=${page}&limit=${limit}`)
    return response
  }

  async getReportsExpenses(start_date, end_date) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    const response = await axios.post('counting/analysis/expenses', {
      start_date,
      end_date,
    })
    return response
  }

  async getReportsSalary(study_year, start_date, end_date) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    const response = await axios.post('counting/analysis/incomes', {
      study_year,
      start_date,
      end_date,
    })
    return response
  }
}

export default new reportsApi()
